import { ReactNode } from "react"

import { Button, ButtonProps, DialogButtons, DialogDescription, DialogHeader, DialogTitle } from "@spatialsys/web/ui"

export type ConfirmModalBaseProps = {
  autoFocusConfirm?: boolean
  title: string
  subtitle?: ReactNode
  message?: ReactNode
  denyText?: string
  confirmText: string
  onDeny?: () => void
  onConfirm: () => void
  linkHref?: string
  confirmButtonColor?: ButtonProps["color"]
  denyButtonClassName?: string
  denyButtonColor?: ButtonProps["color"]
  isLoading?: boolean
}

export function ConfirmModalBase(props: ConfirmModalBaseProps) {
  const {
    autoFocusConfirm,
    title,
    subtitle,
    message,
    denyText,
    confirmText,
    onConfirm,
    onDeny,
    linkHref,
    confirmButtonColor,
    denyButtonClassName,
    denyButtonColor,
    isLoading,
  } = props

  const confirmButtonProps = {
    autoFocus: autoFocusConfirm,
    color: confirmButtonColor ?? "primary",
    onClick: onConfirm,
  }

  return (
    <>
      <DialogHeader>
        <DialogTitle>{title}</DialogTitle>
        <DialogDescription>
          {subtitle}
          {message}
        </DialogDescription>
      </DialogHeader>

      <DialogButtons>
        {denyText && onDeny && (
          <Button
            className={denyButtonClassName}
            color={denyButtonColor ?? "outline"}
            size="lg"
            onClick={onDeny}
            isLoading={isLoading}
          >
            {denyText}
          </Button>
        )}
        {linkHref ? (
          <Button
            {...confirmButtonProps}
            size="lg"
            as="a"
            href={linkHref}
            target="_blank"
            rel="noreferrer"
            isLoading={isLoading}
          >
            {confirmText}
          </Button>
        ) : (
          <Button {...confirmButtonProps} size="lg" isLoading={isLoading}>
            {confirmText}
          </Button>
        )}
      </DialogButtons>
    </>
  )
}
